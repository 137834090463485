import { version } from 'react';
import type { OperationalEventPayload } from '@atlassiansox/analytics-web-client';
import { browserMetrics } from '@atlassian/browser-metrics';
import { getAllFeatureFlags } from '@atlassian/jira-feature-flagging-using-meta';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { sanitiseAssetName } from '@atlassian/jira-platform-sanitise-asset-name/src/index.js';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { ExperiencePerformanceTypes, payloadPublisher, TenantType } from '@atlassian/ufo';
import { bundleEvalTimings } from './bundle-eval-timings/index.tsx';
import { getCustomData, newGetCustomData } from './custom-data/index.tsx';
import { getPageSizeMetrics, getExtraNavigationTimings } from './page-size/index.tsx';
import { featureFlagsAddedToEVERYJiraEvent } from './plugins/feature-flags-added-to-every-jira-event/index.tsx';
import { featureFlagsAddedToEVERYPageLoadEvent } from './plugins/feature-flags-added-to-every-jira-page-load/index.tsx';
import {
	getMemoryMetrics,
	getMarks,
	getPageLoadPlugins,
	sanitiseEndpoints,
} from './plugins/index.tsx';
import { jfpPerfFeatureFlags } from './plugins/jfp-perf-feature-flags/index.tsx';
import {
	getLighthouseMetrics,
	startLSObserver,
	startLTObserver,
} from './plugins/lighthouse-metrics/index.tsx';
import { ssr, ssrExtended } from './ssr/index.tsx';

const isWRMResource = (url: string) => url.includes('/batch.js') || url.includes('/batch.css');
const getWRMAssetName = (url: string) => {
	const [, resources] = /\/([\w.\-,:]*?)(?:,-|\/batch\.)/.exec(url) || [];
	const assetName = sanitiseAssetName(process.env.NODE_ENV !== 'production')(url);
	if (!resources) {
		return assetName;
	}
	return `wrm/${resources}/${assetName}`;
};
const getFileName = (url: string) =>
	isWRMResource(url)
		? getWRMAssetName(url)
		: sanitiseAssetName(process.env.NODE_ENV !== 'production')(url);
/**
 * Sets up the analytics and performance monitoring infrastructure for Jira web pages. This includes initializing metrics collection, configuring feature flags, and preparing plugins for detailed performance analysis. Aimed at enhancing user experience through systematic performance optimization.
 */
export const initBrowserMetrics3 = () => {
	startLSObserver();
	startLTObserver();
	const info = {
		product: 'jira',
		app: {
			version: {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				web: window.BUILD_KEY,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				ssr: window.SSR_BUILD_KEY,
			},
			framework: {
				name: 'react',
				version,
			},
		},
		region: getMeta('ajs-region') || 'unknown',
	};
	const endpoints = {
		eventPipelineClient: getAnalyticsWebClientPromise().then((client) => {
			const instance = client.getInstance();
			if (!fg('uip_bm3_stop-page-load')) {
				return instance;
			}
			return {
				sendOperationalEvent: (payload: OperationalEventPayload) => {
					const type = payload?.attributes?.properties?.['event:type'];
					if (type === 'page-load') {
						return;
					}
					return instance.sendOperationalEvent(payload);
				},
			};
		}),
	};
	const resourceTimings = {
		sanitiseEndpoints,
		mapResources: getFileName,
		hasTimingHeaders: (url: string) =>
			url.includes('jira-frontend-static') ||
			url.includes('jira-frontend-branch') ||
			isWRMResource(url),
		// @ts-expect-error - TS7006 - Parameter 'url' implicitly has an 'any' type.
		xhrFilter: (url) => url.includes('/rest/greenhopper/'),
	};
	const featureFlagClient = {
		getValue: (featureFlagName: string) => {
			const allFeatureFlags = getAllFeatureFlags();
			if (!allFeatureFlags) {
				return undefined;
			}
			return allFeatureFlags[featureFlagName]?.value;
		},
	};
	const featureFlags = {
		client: Promise.resolve(featureFlagClient),
	};
	const plugins = {
		featureFlags,
		resourceTimings,
		bundleEvalTimings,
	};

	getAnalyticsWebClientPromise().then((client) => {
		payloadPublisher.setup({
			product: 'jira',
			app: {
				version: {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					web: window.BUILD_KEY,

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					ssr: window.SSR_BUILD_KEY,
				},
				framework: {
					name: 'react',
					version,
				},
			},
			region: getMeta('ajs-region') || 'unknown',
			tenantType:
				getMeta('ajs-is-synthetic') === 'true' ? TenantType.Synthetic : TenantType.RealUser,
			gasv3: client.getInstance(),
			// @ts-expect-error - TS2322 - Type '{ readonly mapPerformanceMark: (mark: string) => { name: string; type: string; } | null; }' is not assignable to type 'BundleEvalTimingsConfig'.
			bundleEvalTimings,
			resourceTimings,
			// @ts-expect-error - TS2322 - Type '{ getValue: (featureFlagName: string) => FeatureFlagValue | undefined; }' is not assignable to type 'FeatureFlagClient'.
			featureFlagClient,
			featureFlags: [
				...featureFlagsAddedToEVERYJiraEvent,
				...jfpPerfFeatureFlags,
				...featureFlagsAddedToEVERYPageLoadEvent,
			],
			plugins: [
				// all
				getCustomData,
				// @ts-expect-error - TS2322 - Type '(data: {    start: number;}) => Record<string, any> | null' is not assignable to type '(data: ExperienceData | PageLoadExperienceData) => CustomData | null'.
				getMarks,
				getMemoryMetrics,
				// page-load
				getPageSizeMetrics,
				getExtraNavigationTimings,
				// @ts-expect-error - TS2322 - Type '() => (() => unknown)[]' is not assignable to type '(data: ExperienceData | PageLoadExperienceData) => CustomData | null'.
				getPageLoadPlugins,
			],
			ssr,
			performanceConfig: {
				histogram: {
					[ExperiencePerformanceTypes.PageLoad]: {
						initial: {
							fmp: '2500_3000_3500_4000_5000_6000',
							tti: '4000_4500_5000_6000_7000_8000_9000',
						},
						transition: {
							fmp: '2500_3000_3500_4000_5000_6000',
							tti: '2500_3000_3500_4000_5000_6000',
						},
					},
					[ExperiencePerformanceTypes.InlineResult]: {
						result: '100_200_500_1000_1500',
						response: '50_100_150_200_300_500',
					},
					[ExperiencePerformanceTypes.Custom]: {
						duration: '250_500_1000_1500_2000_3000_4000',
					},
				},
			},
		});
	});

	browserMetrics.init({
		info,
		// @ts-expect-error - TS2322 - Type '{ eventPipelineClient: Promise<AnalyticsWebClient> | Promise<IEventPipelineClient>; metalClient: Promise<MetalClient>; }' is not assignable to type '{ eventPipelineClient: Promise<{ sendOperationalEvent: (payload: GasPurePayload) => void; }>; metalClient?: Promise<{ metric: { submit: (payload: unknown) => void; }; }> | undefined; }'.
		endpoints,
		// @ts-expect-error - TS2322 - Type '{ featureFlags: { client: Promise<{ getValue: (featureFlagName: string) => FeatureFlagValue | undefined; }>; }; resourceTimings: { sanitiseEndpoints: (url: string) => string; mapResources: (url: string) => any; hasTimingHeaders: (url: string) => boolean; xhrFilter: (url: any) => any; }; bundleEvalTimings: { ...; }; }' is not assignable to type '{ resourceTimings: ResourceTimingsConfig; bundleEvalTimings?: BundleEvalTimingsConfig | undefined; featureFlags: { client: FeatureFlagsClientPromise; }; }'.
		plugins,
		events: {
			all: {
				featureFlags: [...featureFlagsAddedToEVERYJiraEvent, ...jfpPerfFeatureFlags],
				plugins: [getMarks, getMemoryMetrics, getLighthouseMetrics, newGetCustomData],
			},
			PAGE_LOAD: {
				featureFlags: [...featureFlagsAddedToEVERYPageLoadEvent],
				custom: {
					...getPageSizeMetrics(),
					...getExtraNavigationTimings(),
				},
				// @ts-expect-error - TS2322 - Type '(() => unknown)[]' is not assignable to type 'CustomPlugin[]'.
				plugins: getPageLoadPlugins(),
				histogram: {
					initial: {
						fmp: '2500_3000_3500_4000_5000_6000',
						tti: '4000_4500_5000_6000_7000_8000_9000',
					},
					transition: {
						fmp: '2500_3000_3500_4000_5000_6000',
						tti: '2500_3000_3500_4000_5000_6000',
					},
				},
			},
			INLINE_RESULT: {
				histogram: {
					result: '100_200_500_1000_1500',
					response: '50_100_150_200_300_500',
				},
			},
			CUSTOM: {
				histogram: {
					duration: '250_500_1000_1500_2000_3000_4000',
				},
			},
			WEB_VITALS: {
				enabled: !__SERVER__ && getMeta('ajs-is-synthetic') === 'true',
			},
		},
		// @ts-expect-error - TS2322 - Type '{ readonly includeFeatureFlags: "when-available"; readonly getDoneMark: () => number | null; readonly getTimings: () => any; readonly getFeatureFlags: MemoizedFn<() => any>; }' is not assignable to type 'SSRConfig'.
		ssr: ssrExtended,
	});
	browserMetrics.startPageLoad({
		isInitial: true,
	});
};
