// eslint-disable-next-line jira/restricted/react-suspense
import React, { useContext, Suspense } from 'react';
import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import useLayoutEffectSAFE from '@atlassian/jira-use-layout-effect-safe';
import { type LazySuspenseProps, LazySuspense, WaitContext } from '@atlassian/react-loosely-lazy';

function Hold({ name }: { name?: string }) {
	const interactionContext = useContext(UFOInteractionContext);
	const waitContext = useContext(WaitContext);

	// Runs synchronously, to allow us to identify async items
	useLayoutEffectSAFE(() => {
		// if until context exists means there is a LazyWait parent component
		// we use that value
		const shouldHold = waitContext ? !!waitContext.currentValue() : true;
		if (interactionContext != null && shouldHold) {
			return interactionContext.hold(name);
		}
	}, [interactionContext, name, waitContext]);
	return null;
}

/**
 * @deprecated Use {@link @atlaskit/react-ufo/placeholder#Placeholder} instead.
 */
export default function Placeholder({
	name,
	children,
	fallback,
}: {
	/**
	 * Named Placeholders will help when analysing the break-down of the page load in Performance Portal.
	 * */
	name: string;
	children?: LazySuspenseProps['children'];
	fallback?: LazySuspenseProps['fallback'];
}) {
	// react-18: can use fallback directly
	const fb = fallback != null ? fallback : null;
	// react-18: can use children directly
	const kids = children != null ? children : null;
	// TODO: needed for green VR tests
	ff('uip.placeholder');
	return (
		<LazySuspense
			fallback={
				<>
					{fb}
					<Hold name={name} />
				</>
			}
			name={name}
		>
			{kids}
		</LazySuspense>
	);
}

export function PlaceholderWithNativeSuspense({
	name,
	children,
	fallback,
}: {
	/**
	 * Named Placeholders will help when analysing the break-down of the page load in Performance Portal.
	 * */
	name: string;
	children?: LazySuspenseProps['children'];
	fallback?: LazySuspenseProps['fallback'];
}) {
	// react-18: can use fallback directly
	const fb = fallback != null ? fallback : null;
	// react-18: can use children directly
	const kids = children != null ? children : null;
	// TODO: needed for green VR tests
	ff('uip.placeholder');
	return (
		<Suspense
			fallback={
				<>
					{fb}
					<Hold name={name} />
				</>
			}
		>
			{kids}
		</Suspense>
	);
}

/**
 * This is used specifically for R18 concurrent mode to avoid performance regressions.
 * See: https://hello.atlassian.net/wiki/spaces/JDP/pages/4469891701/Investigate+Concurrent+Mode+Optimisation+for+Navigation
 */
export const PlaceholderNew = componentWithFG(
	'concurrent-native-suspense',
	PlaceholderWithNativeSuspense,
	Placeholder,
);
