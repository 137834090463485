import { getCookie } from '@atlassian/jira-browser-storage-controls/src/utils/get-cookie/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

/*
This logic was ported from MobileUtils class from jira monolith to
provide the same conditions for checking whether the user agent is mobile or not.
*/

const DESKTOP_SWITCH_COOKIE_KEY = 'jira.mobile.desktop.switch';
const ANDROID_CHROME_USER_AGENT = new RegExp('Chrome/[.\\d]* Mobile');
const OLD_ANDROID_USER_AGENT_OUTDATED = new RegExp(
	'Linux;(?: U;)? Android (?:[23]\\.\\d|4\\.0\\.[12])',
);
const MOBILE_USER_AGENT_OUTDATED = new RegExp(
	'(iPhone;|iPhone Simulator;|iPad;|iPod;|iPod touch;|Linux;(?: U;)? Android)',
);
const MOBILE_USER_AGENT = new RegExp('(iPhone;|iPhone Simulator;|iPad;|iPod;|iPod touch|Android)');
const MOBILE_STRING_IN_USER_AGENT = new RegExp('Mobile');
const IPAD_USER_AGENT = new RegExp('iPad;');
const IOS_USER_AGENT = new RegExp('(iPhone;|iPad;|iPod;)');
const ANDROID_USER_AGENT = new RegExp('Android');

// This regex matches the following iOS rendering engines:
// 1. Chrome for iOS
// 2. Firefox for iOS
// 3. Edge for iOS
const NON_SAFARI_IOS_RENDERING_ENGINE_USER_AGENT = new RegExp('CriOS|FxiOS|EdgiOS');

/**
 * Determines if the current user agent is supported based on specific criteria.
 * It checks if the execution is server-side, the presence and validity of the navigator
 * object, and matches against known patterns for Android Chrome and mobile user agents,
 * excluding old Android versions.
 */
export const isSupportedUserAgent = () => {
	if (__SERVER__) return false;

	if (
		fg('jfp-magma-undefined-navigator')
			? !globalThis.navigator?.userAgent.length
			: !navigator || !navigator.userAgent || !navigator.userAgent.length // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	) {
		return false;
	}

	if (fg('jira-fix-firefox-user-agent-detection-2')) {
		const isMobile = MOBILE_USER_AGENT.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		);

		return isMobile;
	}

	return (
		ANDROID_CHROME_USER_AGENT.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		) ||
		(MOBILE_USER_AGENT_OUTDATED.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		) &&
			!OLD_ANDROID_USER_AGENT_OUTDATED.test(
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
			))
	);
};
export const isAndroidUserAgent = () => {
	if (fg('jira-fix-firefox-user-agent-detection-2')) {
		return ANDROID_USER_AGENT.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		);
	}

	return (
		ANDROID_CHROME_USER_AGENT.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		) ||
		OLD_ANDROID_USER_AGENT_OUTDATED.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		)
	);
};

export const isIosUserAgent = () =>
	IOS_USER_AGENT.test(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
	);
/**
 * Checks whether the device's user agent suggests it's a tablet by comparing it
 * against known tablet patterns. Specifically, it identifies iPads and Android
 * tablets, ensuring they are not confused with mobile phones.
 */
export const isTabletUserAgent = () => {
	if (
		fg('jfp-magma-undefined-navigator')
			? !globalThis.navigator?.userAgent.length
			: !navigator || !navigator.userAgent || !navigator.userAgent.length // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
	) {
		return false;
	}
	return (
		IPAD_USER_AGENT.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		) ||
		(isAndroidUserAgent() &&
			!MOBILE_STRING_IN_USER_AGENT.test(
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
			))
	);
};
export const isDesktopSwitchEnabled = () => getCookie(DESKTOP_SWITCH_COOKIE_KEY) === 'true';
const isMobileUserAgent = () => isSupportedUserAgent() && !isDesktopSwitchEnabled();

// it is not possible to detect Safari on iOS directly
// so we need to exclude all other iOS rendering engines
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const isSafariIosRenderingEngine = () => {
	if (typeof navigator === 'undefined') {
		return false;
	}

	return (
		isIosUserAgent() &&
		!NON_SAFARI_IOS_RENDERING_ENGINE_USER_AGENT.test(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			fg('jfp-magma-undefined-navigator') ? globalThis.navigator?.userAgent : navigator.userAgent,
		)
	);
};

export const shouldShowReturnToMobileBanner = () => isSupportedUserAgent();

export const shouldShowReturnToMobileBannerMobileMvp = () => {
	return isSupportedUserAgent() && !isSafariIosRenderingEngine();
};

export const shouldShowDismissButton = () =>
	shouldShowReturnToMobileBanner() && isTabletUserAgent();
export const determineOperatingSystemAttribute = () => {
	if (isIosUserAgent()) {
		return 'ios';
	}
	if (isAndroidUserAgent()) {
		return 'android';
	}
	return 'other';
};
export default isMobileUserAgent;
