import '@atlassian/jira-app-base/src/index.tsx';

import './error-tracking.tsx';
import '@atlassian/jira-browser-metrics';

// Need to disable this because this affects how files are bundled together
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const lazyImports = () => {
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	require('@atlassian/jira-analytics-providers/src/AnalyticsProviders.tsx');
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	require('@atlassian/jira-engagement-provider');
	// eslint-disable-next-line @typescript-eslint/no-require-imports
	require('@atlassian/jira-platform-utils-date-fns');
};

// We set this global variable to ensure it is available to all environments
// and then overwrite its value to TRUE in the JiraSPA entries: SSR and non-SSR
global.__SPA__ = false;
