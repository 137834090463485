export const jfpPerfFeatureFlags = [
	// <jlane>
	'delorean.fix-locale-formats',
	// </jlane>

	// <ldurkan>
	// </ldurkan>

	// <molejniczak>
	// </molejniczak>

	// <etimothy>
	// </etimothy>

	// <lcaseiro>
	// </lcaseiro>

	// <zzheng>
	// </zzheng>

	// <lma>
	// </lma>

	// <ccarloni>
	// </ccarloni>

	// <agasparin>
	'fe_platform_error_tracking_enabled_sentry',
	// </agasparin>

	// <mbiniek>
	// </mbiniek>

	// <yvovk>
	'independent-bump-button-bento_6a9fd',
	// </yvovk>

	// <bduffin>
	// </bduffin>

	// <atay>
	// </atay>

	// <gotham>
	'issue-view-config-type-cleanup-enabled_mz6ah',
	// </gotham>

	// <nhiebl>
	'ptc.organization-id.fetch.enabled',
	// </nhiebl>
] as const;
